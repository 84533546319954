/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
// import Link from 'gatsby-link'
import ReactImageMagnify from 'react-image-magnify'
import { css, jsx } from '@emotion/core'

// import { routes } from '../utils'
import {
  Page,
  H1,
  Hr,
  // GatsbyLink,
  Box,
  Flex,
  FeatherIcon,
  // Text,
  // GatsbyImg,
  Paper,
  Text,
} from '../elements'
import { SEO, HeadingStaticPage } from '../components'

const MapPage = ({ data: { mapSmall, mapLarge } }) => {
  const { aspectRatio } = mapLarge.childImageSharp.fluid
  const largeWidth = 2000
  const largeHeight = largeWidth / aspectRatio

  const images = mapLarge.childImageSharp.fluid.srcSet.split(',').map(size => {
    const a = size.split(' ')
    return {
      src: a[0],
      width: a[1].substring(0, a[1].length - 1),
    }
  })

  return (
    <Page>
      {({ t }) => (
        <React.Fragment>
          <SEO
            {...{
              url: `/map/`,
              title: t('map'),
              // description: true,
            }}
          />
          <HeadingStaticPage
            {...{
              icon: 'map-pin',
              bg: 'lightGreen',
              color: 'textAlt',
              title: t('map'),
            }}
          />

          <Box
            {...{
              width: '100%',
              // css: css`flex-grow:1;`,
            }}
          >
            {/* <Paper
              {...{
                maxWidth: '90%',
                m: 'auto',
                mt: 3,
              }}
            >
              <Text {...{}}>{t('comingSoon')}</Text>
            </Paper> */}
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: 'Map',
                  isFluidWidth: true,
                  // src: images[4].src,
                  src: mapSmall.childImageSharp.fluid.src,
                  srcSet: mapSmall.childImageSharp.fluid.srcSet,
                  sizes: mapSmall.childImageSharp.fluid.sizes,
                },
                largeImage: {
                  src: images[4].src,
                  // src: mapSmall.sizes.src,
                  // srcSet: mapSmall.sizes.srcSet,
                  // sizes: mapSmall.sizes.sizes,
                  width: largeWidth,
                  height: largeHeight,
                },
                isHintEnabled: true,
                shouldHideHintAfterFirstActivation: false,
                hintTextMouse: t('mapHintTextMouse'),
                hintTextTouch: t('mapHintTextTouch'),
                enlargedImagePosition: 'over',
                // isActivatedOnTouch: true,
                pressDuration: 100,
              }}
            />
          </Box>
        </React.Fragment>
      )}
    </Page>
  )
}

export const pageQuery = graphql`
  query MapQuery {
    mapSmall: file(relativePath: { regex: "/bgf2019Map/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
    }
    mapLarge: file(relativePath: { regex: "/bgf2019Map/" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default MapPage
